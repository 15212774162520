import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { MatStepperModule } from '@angular/material/stepper'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule} from '@angular/material/select';
import { MatPaginatorModule}  from '@angular/material';
import { MatCardModule}  from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material';
import { MatExpansionModule} from '@angular/material';
import { MatCheckboxModule, MatIconModule, MatTooltipModule} from '@angular/material'

import { Globals } from '../../global';
import { FileSaverModule }from 'ngx-filesaver';
 
import { ConditionsComponent } from './home/conditions.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
 
    ConditionsComponent
   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSelectModule,
    MatPaginatorModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule,
    FormsModule, 
    ReactiveFormsModule,
    HttpClientModule,
    FileSaverModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatCheckboxModule, MatIconModule ,
    AngularFontAwesomeModule, MatTooltipModule
    
  ],
  providers: [Globals],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  baseUrl: string = "/api/store";

  constructor(private httpClient: HttpClient) { }

  public saveTemplate(obj) {
    
    try {
      return this.httpClient.post(this.baseUrl, obj, {
        headers: new HttpHeaders({
          'Accept': 'application/json, */*',
          'Content-Type': 'text/plain'
        }),
        'responseType': 'text'

      }).subscribe(
        data => alert(data),
        error => alert("error " + error.message)
      );
    }
    catch (e) {
      alert(e);
    }

  }

}
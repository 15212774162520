import { Component, Input, OnInit } from '@angular/core';
import { Condition } from '../models/conditions';
import { MatTable, MatTableDataSource, MAT_HAMMER_OPTIONS } from '@angular/material';



export interface ConditionList {
    value: string;
    viewValue: string;
  }
   
@Component({
  selector: 'conditions',
  templateUrl: './conditions.component.html'
})

export class ConditionsComponent implements OnInit {
    
  @Input() conditions : Condition[];
  @Input() displayHeading : boolean = false;
  displayedColumns = ['condition','LeftValue', 'Comparison', 'RightValue'];
  dataSource : MatTableDataSource<Condition>;
   
  _comparison_list: ConditionList[] = [
    {value:"if", viewValue:"if"},
    {value:"or", viewValue:"or"},
    {value:"else", viewValue:"else"},
    {value:"and", viewValue:"and"}
]

  _condition_list: ConditionList[] = [
        {value:"=", viewValue:"="},
        {value:">", viewValue:">"},
        {value:"<", viewValue:"<"},
        {value:"in", viewValue:"in"},
        {value:"<>", viewValue:"<>"}
    ]


  ngOnInit() {
    if (this.areConditionsValid(this.conditions)) {
      this.dataSource = new MatTableDataSource(this.conditions);
    }
    
  }

  areConditionsValid(conditions : Condition[]) : boolean {
    return typeof conditions != "undefined" && conditions != null && conditions.length > 0;
  }

}

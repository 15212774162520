
import { Component, OnInit, ViewChild, Inject, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TemplateService } from '../template.service';
import { Globals } from '../../../global';


import { _MatTabGroupBase, MAT_HAMMER_OPTIONS } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { FileSaverService } from 'ngx-filesaver';
import { PageEvent } from '@angular/material/paginator';
// ACE
import * as ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-clouds';
import 'ace-builds/src-noconflict/ext-options';

import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/text';
import 'ace-builds/src-noconflict/snippets/python';

import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MappingModel } from '../models/mapping';


const THEME = 'ace/theme/clouds';
const LANG = 'ace/mode/python';

export interface TargetList {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})



export class HomeComponent implements OnInit {

  @Input() mappings: MappingModel[];
  displayedColumns = ['SourceKey', 'TargetKey', 'DefaultValue', 'DisplayConditions'];
  dataSources: MatTableDataSource<MappingModel>[];

  expandedElement: MappingModel;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  _flatten: Variable;


  _togglePanel: boolean;
  _row: number;
  _mappingTemplate: string;
  _sourcekeys: string[];
  _targetkeys: string[];

  sourceJSON = new FormControl();
  targetJSON = new FormControl();

  pageEvent: PageEvent;

  tableRows: MappingModel[] = []

  dataSource = null;
  result = {};
  _recurseSource: boolean;

  _conditions: string[] = ['IF'];
  _valuations: string[] = ['==', '>', '<', '<>', 'in'];
  _TABS: string = "";

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('tableCTRL', { static: true }) tableCTRL: MatTable<Element>;
  @ViewChild('codeEditor', { static: true }) codeEditorElmRef: ElementRef;
  private codeEditor: ace.Ace.Editor;
  text: string = "";
  checkLine: string = "";
  _targetList: TargetList[] = [
    { value: 'target', viewValue: 'targetValue' },

  ];


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(private _formBuilder: FormBuilder,
    public template: TemplateService, private globals: Globals,
    private _FileSaverService: FileSaverService

  ) {
    this._sourcekeys = this.globals._sourceKeys;

  }

  ngOnInit() {
    ace.require('ace/ext/language_tools');
    ace.config.set('basePath', '/ace-builds/src-noconflict');
    this._togglePanel = false;
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    const element = this.codeEditorElmRef.nativeElement;
    const editorOptions = this.getEditorOptions();

    this.codeEditor = ace.edit(element, editorOptions);
    this.codeEditor.setTheme(THEME);
    this.codeEditor.getSession().setMode(LANG);
    this.codeEditor.setShowFoldWidgets(true); // for the scope fold feature
    this.codeEditor.getSession().setUseWorker(false);
    this.dataSources = new Array<MatTableDataSource<MappingModel>>();
    if (this.areMappingsValid(this.mappings)) {
      this.dataSources = new Array<MatTableDataSource<MappingModel>>();
      for (let mapping of this.mappings) {
        this.setDataSources(mapping);
      }
    }
  }

  private setDataSources(mapping: MappingModel) {
    let mappings = new Array<MappingModel>();
    mappings.push(mapping);
    this.dataSources.push(new MatTableDataSource(mappings));
  }

  // missing propery on EditorOptions 'enableBasicAutocompletion' so this is a wolkaround still using ts
  private getEditorOptions(): Partial<ace.Ace.EditorOptions> & { enableBasicAutocompletion?: boolean; } {
    const basicEditorOptions: Partial<ace.Ace.EditorOptions> = {
      highlightActiveLine: true,
      minLines: 14,
      maxLines: Infinity,
      printMargin: false,
      displayIndentGuides: true
    };

    const extraEditorOptions = {
      enableBasicAutocompletion: true,
      enableSnippets: true,
      enableLiveAutocompletion: true,

    };
    const margedOptions = Object.assign(basicEditorOptions, extraEditorOptions);
    return margedOptions;
  }

  areMappingsValid(mappings: MappingModel[]): boolean {
    return typeof mappings != "undefined" && mappings != null && mappings.length > 0;
  }

  onChange(code) {
    // console.log("new code", code);
  }

  onSaveTemplate() {
    try {
    this._FileSaverService.save(new Blob([this.codeEditor.getValue().toString()], { type: ' text/plain;charset=utf-8' }), "template.py");

    //this.template.saveTemplate( this.codeEditor.getValue().toString() ) ;
  } catch(e) {
    alert(e);
  }
  }

  onSourceChange() {
    try {
      this._recurseSource = true;
      this.tableRows.splice(0, this.tableRows.length);
      this.recurse(JSON.parse(this.sourceJSON.value), "");

      this.sourceJSON.setValue(JSON.stringify(JSON.parse(this.sourceJSON.value), null, 4));
      this.dataSource = new MatTableDataSource(this.tableRows);

      this.dataSource.paginator = this.paginator;

    } catch (e) {
      alert(e);
    }
  }


  nextPage() {
    //this.dataSource.data = this.tableRows;
  }

  onSelectChange() {
    try {
      this.text = "";
      this._mappingTemplate = '';

      this.tableRows.forEach(element => {
        this._TABS = ''
        if (!element.TargetKey.trim().includes("target")) {
          this.checkConditions(this._mappingTemplate, element);
        }
      });

      this.codeEditor.setValue(this._mappingTemplate);
      this.dataSource = new MatTableDataSource(this.tableRows);
      this.dataSource.paginator = this.paginator;

    } catch (e) {
      alert(e)
    }
  }

  checkConditions(_mappingTemplate: string, _el: MappingModel) {
    var rootKey = _el.TargetKey;
    /*if (_el.TargetKey.includes('[')) {
      rootKey = this.pathToKey(_el.TargetKey);
      this._TABS = this._TABS + '\t';
    }

    _el.conditions.forEach(cond => {
      if (cond._C_Cond.length>1) {
        this._mappingTemplate = this._mappingTemplate + "\n" + this._TABS + cond._C_Cond + " " + cond._C_LFieldValue + " " + cond._C_Eq + " " + cond._C_RFieldValue + ":";
        this._TABS = this._TABS + '\t';
      }
    });*/

    if ((_el.DefaultValue.trim() == "")) {
      this._mappingTemplate = this._mappingTemplate + "_OUT." + rootKey + " = _IN." + _el.SourceKey + "\n";
    } else {
      this._mappingTemplate = this._mappingTemplate + "_OUT." + rootKey + "= '" + _el.DefaultValue + "\n";
    }

  }

  pathToKey(item) {

    var items = item.split('.')

    var out = ""
    var x = 0
    var key = ''
    this._TABS = ''
    var re = /\[.*\]/gi;  // remove subscripts

    items.forEach(itm => {
      if (itm.includes('[')) {
      
        var l1 = item.split(itm)
        itm = itm.replace(re, "");
        if (x == 0) {
          out = "for _" + itm + " in " + l1[0] + itm + ":";
          x = 1
          key = "_" + itm
        } else {
          this._TABS = this._TABS + '\t'
          out = "\n" + this._TABS + "for _" + itm + " in " + key + '.' + itm + ":";
          x = 1
          key = "_" + itm
        }
        this._mappingTemplate = this._mappingTemplate + out;
        out = "";
      }
    });
    out = ""
    var p = items.length;
    key = key.replace(re, "");
    out = key + '.' + items[p - 1];

    return out

  }


  onTargetChange() {
    this._recurseSource = false;
    this._targetList.splice(0, this._targetList.length);
    this.recurse(JSON.parse(this.targetJSON.value), "");

  }

  ngOnDestroy() {

  }


  recurse(cur, prop) {
    if (Object(cur) !== cur) {
      //this.result[prop] = cur;
      if (this._recurseSource) {
        this.tableRows.push(({
          SourceKey: prop,
          TargetKey: "target",
          DefaultValue: "",
          DisplayConditions: false,
          conditions: [{ _C_Cond: "", _C_LFieldValue: "", _C_Eq: "", _C_RFieldValue: "" }]
        }))
        //this.dataSources.push(new MatTableDataSource(this.tableRows))
      } else {
        this._targetList.push(({ value: prop, viewValue: prop }))
      }
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        this.recurse(cur[i], prop + "[" + i + "]");
      //f (l == 0) this.result[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        this.recurse(cur[p], prop ? prop + "." + p : p);
      }
      //if (isEmpty && prop) this.result[prop] = {};
    }
  }



}